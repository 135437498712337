import { Br, PageHeader } from "components";
import React from "react";

const RailsPageHeader = () => {
  return (
    <PageHeader
      title={
        <>
          Bring your fintech
          <Br on="desktop" />
          idea to life.
        </>
      }
      subheading={
        <>
          We are the trusted partners who help you realise your fintech dreams.
        </>
      }
      button={{ text: "Start Building", to: "https://parkway.mintlify.com/" }}
      secondButton={{
        text: "Sign in to Rails",
        to: "https://rails.parkway.ng",
      }}
    />
  );
};

export { RailsPageHeader };
