import React from "react";
import { FeatureCard, FeatureCardAlt } from "components";
import { StaticImage } from "gatsby-plugin-image";

const RailsFeatures = () => {
  return (
    <div>
      <FeatureCard
        title="e-Wallets"
        description="Integrate with a stable infrastructure that provides your solution with comprehensive features for creating, activating, and managing digital wallets for your customers while leveraging competitive pricing, unlimited wallet generation, NUBAN-compliant wallet ID, real-time support, and so much more."
        buttonText="Explore e-Wallet services"
        pageLink="https://parkway.mintlify.com/service-endpoints/create-customer-wallet"
        image={
          <StaticImage
            src="../../../../assets/images/jpegs/rails/ewallet.jpg"
            alt="features"
          />
        }
        anchor="ewallet"
      />

      <FeatureCardAlt
        title="Cards"
        description="Parkway provides a one-stop shop for issuing payment cards through your platform. Our solution allows partners to generate and manage virtual and physical cards quickly. The cards are customisable and built with encoding features."
        buttonText="Explore card services"
        pageLink="https://parkway.mintlify.com/service-endpoints/card-endpoints/link-card-to-wallet"
        image={
          <StaticImage
            src="../../../../assets/images/jpegs/rails/card-rails.jpg"
            alt="features"
          />
        }
        anchor="cards"
      />

      {/* <FeatureCard
        title="Virtual account"
        description="With our comprehensive API suite for dynamic accounts, you gain the ability to effortlessly create, manage, and receive customer payments. The account number serves as a unique identifier for customers, specific transactions, or invoices, providing you with seamless control over your financial operations."
        buttonText="Explore virtual account services"
        pageLink="https://parkway.mintlify.com/service-endpoints/virtual-account-introduction"
        image={
          <StaticImage
            src="../../../../assets/images/jpegs/rails/virtual-account.jpg"
            alt="features"
          />
        }
        anchor="virtual-account"
      /> */}

      <FeatureCard
        title="Utility Service"
        description="Integrate banking and financial services into your application. You can provide services such as bill payments, account opening, and BVN registration through our API service."
        buttonText="Explore utility services"
        pageLink="/get-demo"
        image={
          <StaticImage
            src="../../../../assets/images/jpegs/rails/utility-service.jpg"
            alt="features"
          />
        }
        anchor="utility-service"
      />
    </div>
  );
};

export { RailsFeatures };
