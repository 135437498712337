import * as React from "react";

import { Layout } from "components";
import { RailsPageHeader, RailsFeatures } from "templates/solutions/rails";

const RailsPage = () => (
  <Layout title="Rails">
    <RailsPageHeader />
    <RailsFeatures />
  </Layout>
);

export default RailsPage;
